@media screen and (max-width: 680px) {
	.main_sec{
		width: 100%;
	}
	.services_sec{
		width: 100%;
		margin: 0 auto;
		padding: 0;
	}
	.services_sec .rows_div .item_1{
		width: 100%;
		margin: 0;
		border-radius: 0;
	}
	.services_sec .rows_div{
		margin: 0;
	}
	.main_sec .head .left_sec a{
		margin-top: 0;		
	    font-size: 15px;
	    display: block;
	    display: none;
	    /*pointer-events: none;*/
	}

	.main_sec .head.admin_header .headInner{
		height: 65px;
	}

	.main_sec .head.admin_header .right_sec{
		width: auto;
		margin-top: 3px;
		background: transparent;
		padding:0;
	}

	.main_sec .head.admin_header .left_sec{
		width: auto;
	}

	.main_sec .head .left_sec a span{
		display: none;
	}
	.main_sec .head .right_sec h3 {
	    color: #fff;
	    margin-top: 0;
	    font-size: 12px;
	}

	.list_page_sec .list_page_sec_inner{
		width: 100%;
		padding: 0;
		/*min-height: 57vh;*/
	}
	.recomended{
		right: 0;
	}
	.list_page_sec .list_page_sec_inner .all_rows .first_row{
		width: 100%;
		background: #f1f1f1;
		margin: 0;
		border-radius: 0;
		height: inherit;
    	padding: 35px 0;
	}
	.life_insurance_logo{
		display: block;
	    width: 68%;
	    clear: both;
	    float: right;
	}
	.life_insurance_logo img{
		display: inline-block;
	}
	.list_page_sec .list_page_sec_inner h3{
		font-size: 24px;
	    padding-bottom: 30px;
	    padding-top: 20px;
	    margin-bottom: 0;
	}
	.list_page_sec .list_page_sec_inner .all_rows .first_row .right_text h4{

	}
	.list_page_sec .list_page_sec_inner .all_rows .first_row .right_text h4 a{
		color: #fff !important;
	}
	.list_page_sec .list_page_sec_inner .all_rows .first_row .right_text p{	
		width: auto;
		white-space: inherit;
		overflow: inherit;
		text-overflow: inherit;
	}	

	.list_page_sec .list_page_sec_inner .all_rows{
		width: 100%;
		margin-top:0;
		text-align: center;
	}
	.list_page_sec .list_page_sec_inner .all_rows #first_row_7_85, .list_page_sec .list_page_sec_inner .all_rows #first_row_7_86, .list_page_sec .list_page_sec_inner .all_rows #first_row_7_87, .list_page_sec .list_page_sec_inner .all_rows #first_row_7_88, .list_page_sec .list_page_sec_inner .all_rows #first_row_7_89, .list_page_sec .list_page_sec_inner .all_rows #first_row_7_90,.list_page_sec .list_page_sec_inner .all_rows #first_row_7_91, .list_page_sec .list_page_sec_inner .all_rows #first_row_7_92, .list_page_sec .list_page_sec_inner .all_rows #first_row_7_93{
		height: auto;
	}
	
	.list_page_sec .list_page_sec_inner .all_rows .first_row .right_text{
		width: 90%;
		margin: 0;
		padding: 0;
		float: none;
		margin:0 auto;
		height:115px;
	}
	.list_page_sec .list_page_sec_inner .all_rows .first_row .right_text h4{
		font-size: 18px;
	}


	.section_four .section_four_inner{
		width: 90%;
		margin:0 auto;
	}
	.section_four .section_four_inner .left1 ul li span{
		width: 100%;
	}
	.section_four .section_four_inner .rhgt1{
		display: none;

	}

	.cc_section_two .cc_section_twoInner .cc_section_twoInner_img1{
		display: none;
	}
	.cc_section_two .cc_section_twoInner{
		width: 90%;
		margin: 0 auto;
		padding-top: 20px;
	}
	.cc_section_two .cc_section_twoInner .cc_section_twoInner_txt1{
		float: none;
		width: 100%;
		padding-top: 20px;
	}

	.cc_section_two .cc_section_twoInner .cc_section_twoInner_txt1 ul li span{
		width: 92%;
	}
	.cc_section_three .cc_section_threeInner{
		width: 90%;
		margin: 0 auto;	
	}
	.cc_section_three .cc_section_threeInner .lft1{
		float: none;
		width: 100%;
	}
	.cc_section_three .cc_section_threeInner .rght1{
		/*display: none;*/
		float: none;
		width: 95%;
		margin: 0 auto;
		margin-top: 20px;
	}
	.section_four .section_four_inner .left1{
		width: 100%;
		float: none;
	}
	.form_mbNum{
		width: 100%;
		margin-left: 0;
	}
	.section_four .section_four_inner .left1 input{
		width: 100%;
	}
	.section_four .section_four_inner .left1 button.top_apply_btn{
		width: 100%;
		margin-left: 0;
		margin-top: 10px;
	}
	.list_page_sec .list_page_sec_inner .all_rows .first_row .left_img{
		width: 70%;
		float:none;		
		height: auto;
		margin:0 auto;
		margin-bottom: 20px;
		margin-top:20px;
	}
	.list_page_sec .list_page_sec_inner .all_rows .first_row .left_img img{
		margin-top: 0 !important;	
		height:20px;
		width:auto;
	}


	/*--- reports page css ---*/

	.hover_dropDown .hamburger_icon{
		display: block;
		margin-left: 20px;
	}
	.main_sec.report_page .left_sec{
		width: 100%;
		margin:0 auto;
	}

	.hover_dropDown .hamburger_icon img{
		width: 30px;
	}
	.main_sec.report_page .hover_dropDown ul{
		/*width: 90%;
		padding: 20px 0;
		display: none;
		position: absolute;
		left: 0;
		background: #009643;
		width: 100%;
		z-index: 9999;*/
		overflow-y: scroll;
	}
	.main_sec.report_page .hover_dropDown ul li{
		display: block;
		float: none;
		margin: 0;
	}
	.main_sec.report_page .hover_dropDown ul li a{
		width: auto;
	    padding: 14px 20px;
	}
	.main_sec.report_page .head .headInner{
		width: 95%;
	}

	.main_sec.report_page .services_sec .row .firstBlock{
		min-width: 90%;
    	flex: 0 90%;
    	margin: 0 auto;
    	margin-top: 20px;
	}
	.main_sec.report_page .services_sec .row{
		margin: 0;
	}


	/*--- reports page css ---*/


	.section_four .section_four_inner .left1 span{
		font-size: 22px;
		width: 87%;
	}

	.section_four .section_four_inner .left1 ul li{

	}

	.section_four .section_four_inner .left1 img{
		margin-right: 5px;
		width: 30px;
	}
	.section_four .section_four_inner .left1 ul li{
		font-size: 16px;
    	line-height: 20px;
    	margin-top: 10px;
	}
	#toggleDiv{
		margin-left: 0;
	}
	.main_content ul{
		height: auto;
		overflow: inherit;
	}
	.section_four .section_four_inner .left1 span#toggle-text{
		display: none;
	}
	

	.cc_section_two .cc_section_twoInner .cc_section_twoInner_txt1 h3 span{
		font-size: 22px;
	}

	.cc_section_two .cc_section_twoInner .cc_section_twoInner_txt1 h3 img{
		width: 25px;
	}

	.cc_section_two .cc_section_twoInner .cc_section_twoInner_txt1 ul li{
		font-size: 16px;
		padding-left: 0;
	}
	.cc_section_three .cc_section_threeInner .lft1 ul li{
		padding-left: 0;
	}
	.hover_dropDown ul li.creditCardToggle .creditCardIcon{
		background: #fff;
		padding: 3px 3px;
		border-radius: 20px;
		width: 16px;
		opacity: 100%;
	}
	.content.forNewWraper{
		width: 95%;
		margin: 0 auto;
		padding-top: 20px;
	}


	/*--- home page ----*/

	.services_sec .rows_div h2{
		font-size: 22px;
		padding: 20px 0;
	}

	/*---header css ----*/

	.middle_section{
		display: none;
	}
	.main_sec .head{
		/*position: relative;*/
		height: auto;
	}
	/*.referFriend-anchor{
		position: absolute;
		top: 55px;
		width: 100%;
		left: 0;
		
	}*/
	.main_sec .head .right_sec{
		padding: 5px 10px;
	    width: 100%;
	    border-radius: 6px;
	    margin-top: 13px;
	    text-align: left;
	    background: rgba(0, 0, 0, 0.2);
	}
	.main_sec .head .right_sec .referFriendBtn a.referFriend.R_one img{
		display: none;
	}
	.main_sec .head .right_sec .referFriendBtn .total_coins .total_coins_inner a img{
		display: inline-block;
	}
	.main_sec .head .right_sec .referFriendBtn a.referFriend{
		height: 22px;
		line-height: 21px;
		font-size: 11px;
		width: 90px;
		border: 1px solid #fff;
	}
	.main_sec .head .right_sec .referFriendBtn a.referFriend.R_one{
		height: 22px;
		line-height: 21px;
		font-size: 11px;
		width: 90px;
		border: 1px solid #fff;
	}
	.main_sec .head .right_sec .referFriendBtn a.referFriend.R_two{
		height: 22px;
		line-height: 21px;
		font-size: 11px;
		width: 90px;
		border: 1px solid #fff;
	}
	.head .headInner .right_sec .user_setting_dropDown{
		top:40px;
		width:170px;
	}
	.main_sec .head .left_sec{
		width: 100%;
	}
	.main_sec .head .left_sec a img.home_icon{
		display: none;
	}
	.main_sec .head .left_sec .left_sec_inner .hover_dropDown ul li a img.home_icon{

	}
	.main_sec .head .left_sec .left_sec_inner{
		width: 100%;
    padding: 0;
    text-align: left;
	}
	
	.refer_toggle .refer_toggle_1 .refer_toggle_inner .share_btn a{
		margin: 0;
	}
	/*.main_sec .head .right_sec .referFriendBtn .referFriend-anchor a{
		background: #fff;
	    border: none;
	    border-radius: 0;
	    color: #009643;
	    margin: 0;
	    width: 100%;
	    margin-left: 0px;
	    height: 35px;
	    line-height: 35px;
	}*/
	.r_two_refer_toggle .refer_toggle_1 .refer_toggle_inner .share_btn a{
		margin: 0;
		display: inline-block;
	}
	.r_two_refer_toggle .refer_toggle_1 .refer_toggle_inner .share_btn a img{
		display: block !important;
	}
	#google_translate_element select{
		font-size: 10px;
	    width: 65px;
	    border: 1px solid #fff !important;
	    padding-left: 6px;
	    height: 20px;
	    margin: 0;
	    padding-right: 0;
	}
	.main_sec .user_name{
		margin: 0;
		margin-top: 3px;
		float: right;
    	margin-right: 13px;
	}
	.main_sec .head .headInner{
		padding: 20px 0;
		margin: 0 auto;
    	height: 103px;
	}
	#google_translate_element{
		height: 24px;
		margin-top: 0 !important;
	}
	.goog-te-gadget-simple{
		font-size: 11px !important;
	    padding: 5px 8px !important;
	    border-width: 1px !important;
	}

	.main_sec .head .left_sec a img.arrow{
		display: none;
	}
	.main_sec .head .left_sec img.hamberger{
		display: inline-block;
	    width: 25px;
	    margin-left: 3px;
	}
	.main_sec .head .left_sec img.wl_logo{
		display: inline-block;
	    width: 120px;
	    margin-left: 15px;
	    vertical-align: middle;
	}
	.main_sec .head .left_sec .left_sec_inner:hover .hover_dropDown{
		display: none;
	}
	.main_sec .head .left_sec .hover_dropDown{
	    left: 0;
	    width: 78%;
	    background: #009643;
	    box-shadow: none;
	    max-height: 100%;
	    top: 55px;
	    overflow-y: scroll;
	}
	.main_sec .head .left_sec .hover_dropDown ul li a{
		color: #fff;
		padding: 16px 25px;
	}
	body.overflow_hidden{
		overflow: hidden;
	}
	.main_sec .head .left_sec ul li a img{
		background: #fff;
		padding: 3px 3px;
		border-radius: 20px;
		width: 15px;
		opacity: 100%;
	}
	.main_sec{
		/*min-height: inherit;*/
		position: relative;
	}
	.sweet-alert{
		top: 27%;
	}

	/*--- report page css ---*/

	.creditCardPincode .creditCardPincode_inner .insurance_filter ul{
		width:100%;
		border-radius: 0;
		box-shadow: none;
	}
	.creditCardPincode .creditCardPincode_inner .insurance_filter{
		margin-top:0;
	}
	.creditCardPincode .creditCardPincode_inner .insurance_filter ul li span{
		padding-top:0;
	}
	.creditCardPincode .creditCardPincode_inner .insurance_filter ul li{
		width: 96%;
    padding: 0;
    border-radius: 0;
    /* margin: 0; */
    padding: 10px 0;
    border-top: 1px solid #e6eefa;
	}

	.creditCardPincode .creditCardPincode_inner .insurance_filter ul li:first-child{
		border:none;
	}

	.creditCardPincode .creditCardPincode_inner .insurance_filter ul li img{
		display: none;
	}


	.reportLogin_popup .login_popup{
		width: 95%;
		margin-left: 0;
		margin-right: 0;
		left: 50%;
		top: 50%;
		z-index: 99999999;
		transform: translate(-50%,-50%);
	}

	.reportLogin_popup .login_popup .login_popupInner h3{
		color: green;
	}

	/*--- apply form ---*/

	.main_sec.loan_form .content-wrapper.forNewWraper{
		width: 95%;
	}
	.content-wrapper.forNewWraper .content{
		width: 100%;
		padding: 0;
	}
	.content-wrapper.forNewWraper .content .container-fluid{
		padding: 0;
	}
	.main_sec.report_page .content-wrapper.forNewWraper{
		margin: 0 auto !important;
	    width: 95%;
	    margin-top: 20px !important;
	}
	.main_sec .head.admin_header .left_sec ul li a img{
		width: 22px;
	}
	.loanFormSec .row .col-lg-6{
		max-width: 100%;
	}
	.frm.loanFormSec{
		margin-top: 20px;
	}
	.loanFormSec .row{
		margin-bottom: 0;
	}

	.container-fluid.applyform{
		width: 100%;
	}
	.container-fluid.applyform .row .col-lg-6 {
	    flex: 0 0 99%;
	    max-width: 99%;
	}	
	.card.card-success.salarySlip h3{
		line-height: normal;
    	margin-top: 5px;
	}
	.container-fluid.applyform.eduacation_form_design .card.card-success.salarySlip .personal_detail_title h3 img{
		margin-top: -17px;
	}
	.container-fluid.applyform .salarySlip .card-header h3 img{
		margin-top: 0;
	}

	/*--- insurance page css*/

	.insurance1_div .section_one{
		width: 100%;
	}
	.insurance1_div .section_one .I_leftSec{
		width: 100%;
		float: none;
		padding: 0;
		margin: 0;
	}
	.insurance1_div .section_one .I_leftSec.video_section ul li{
		width: 100%;
	}
	.insurance1_div .section_one .I_rightSec{
		display: none;
	}
	.insurance1_div .section_two{
		width: 100%;
	}
	.insurance1_div .section_two .one{
		width: 25%;
		margin-top: 0;
		height: 110px;
	}
	.insurance1_div .section_two .one img{
		width: 100%;
	}
	.insurance1_div .section_two .one h3{
		font-size: 10px;
		line-height: 14px;
	}
	.insurance1_div .section_three{
		margin-top: 0;
		width: 100%;
		padding: 0;
	}
	.section_five .section_five_inner .rhgt1 ul li h3 strong{
		font-size: 16px;
	}
	.section_five .section_five_inner .rhgt1 ul li h3 span{
		font-size: 16px;
		line-height: 22px;
		margin-top: 10px;
	}
	.insurance1_div .section_three .left{
		float: none;
		width: 100%;
		padding: 0;
		padding-bottom: 20px;
		padding-top: 10px;
	}
	.insurance1_div .section_three .right{
		max-width: 100%;
		padding: 0;
		float: none;
		margin-left: 0;
		margin-top: 0;
		padding-bottom: 20px;
		padding-top: 15px;
	}
	.insurance1_div .section_three .right h2{
		margin: 0 auto;
	    margin-bottom: 10px;
	    width: 90%;
	    padding-top: 0px;
	    font-size: 22px;
	    margin-bottom: 0px;
	}
	.insurance1_div .section_three .right ul{
		margin: 0 auto;
		padding: 0;
		width: 90%;
	}
	.insurance1_div .section_three .right ul li{
		padding-bottom: 0;
		margin-bottom: 0px;
		margin-top: 10px;
	}
	.section_four .section_four_inner .left1 h3{
		padding-bottom: 10px;
		font-size: 24px;
	}
	.section_five .section_five_inner{
		width: 100%;
		margin-top: 0;
		padding-bottom: 0;
	}
	.section_five .section_five_inner .left1{
		float: none;
		padding: 0;
		width: 100%;
		text-align: center;
	}
	.section_five .section_five_inner .left1 iframe{
		width: 96%;
	}
	.section_five .section_five_inner .left1 .downloadPdfBtn{
		width: 95%;
		margin: 0 auto;
	}
	.section_five .section_five_inner .left1 .downloadPdfBtn a{
		width: 100%;
	}
	.section_five .section_five_inner .rhgt1{
		width: 90%;
	    float: none;
	    height: auto;
	    margin: 0 auto;
	    margin-top: 20px;
	    padding: 0px;
	    padding-top: 15px;
	    box-shadow: none;
	}
	.insurance1_div .section_three .left h3{
		width: 90%;
		margin: 0 auto;
		font-size: 22px;
	}
	.insurance1_div .section_three .left p{
		width: 90%;
		margin: 0 auto;
		line-height: 26px;
	}
	.insurance1_div .section_one .I_leftSec.video_section h3{
		font-size: 28px;
	}
	.insurance1_div .section_three .right ul li h3{
		width: 86%;
		margin: 0 auto;
		margin-left: 10px;
		margin-top: 5px;
	}
	.insurance1_div .section_three .left a{
		max-width: 100%;
	    width: 85%;
	    margin: 0 auto;
	    padding: 0;
	    height: 40px;
	    line-height: 40px;
	    margin-top: 10px;
	}
	.insurance1_div .section_three .right ul li h3 strong{
		font-weight: normal;
    	margin-bottom: 5px;
    	font-size: 16px;
	}
	.insurance1_div .section_three .right ul li h3 span{
		line-height: 20px;
	}
	.section_four .section_four_inner .left1 button{
	    width: 90%;
	    margin: 0 auto;
	    padding: 0;
	    height: 40px;
	    line-height: 40px;
	    margin-top: 10px;
	    display: block;
	}
	.section_four .section_four_inner .left1 button#lnk_view_limited_plan{
		width: 100%;
		margin-top: 20px;
	}
	.section_five .section_five_inner .rhgt1 h2{
		width: 90%;
		margin: 0 auto;
		margin-top: 0px;
		font-size: 22px;
		margin-bottom: 20px;
		margin-left: 0;
	}
	.insurance1_div .section_one .I_leftSec .quote_div a{
		line-height: 38px;
	}
	.section_five .section_five_inner .rhgt1 p{
		width: 90%;
		margin: 0 auto;
		line-height: 26px;
	    font-size: 16px;
	}
	.section_five .section_five_inner .rhgt1 p strong{
		margin-top: 10px;
		margin-bottom: 10px;
		display: block;
	}
	.insurance1_div .section_one .I_leftSec.video_section .quote_div a{
		width: 100%;
	    margin: 0 auto;
	    padding: 0;
	    height: 40px;
	    line-height: 40px;
	    margin-top: 10px;
	    display: block;
	}

	/*--- refer a friend css ---*/

	.refer_toggle .refer_toggle_1{
		width: 100%;
		left: 0;
		top: 49px;
	}
	.refer_toggle{
		position: inherit;
	}

	.r_two_refer_toggle .refer_toggle_1{
		width: 100%;
		left: 0;
		top: 30px;
	}
	.r_two_refer_toggle{
		position: inherit;
	}
	.insurance1_div .section_one .I_leftSec span{
		width: 90%;
	    margin: 0 auto;
	    display: block;
	    padding-top: 4px;
	}
	.insurance1_div .section_one .I_leftSec h2{
		width: 90%;
		margin: 0 auto;
	}
	.insurance1_div .section_one .I_leftSec span{
		padding-top: 15px;
	}
	.section_five .section_five_inner .rhgt1 ul li h3{
		width: 95%;
		margin-left: 0;
	}

	.main_sec.registration_page{
		padding-top: 0;
	}

	#finacial-registration{
		width: 90%;
	}
	.hover_dropDown ul li.mb-home{
		display: block;
	}

	/*--- credit card pincode ---*/

	.creditCardPincode .creditCardPincode_inner .form_div{
		float: none;   
		width: 90%;
		margin: 0 auto;
		text-align: left;
		padding-bottom: 10px;
	}
	.creditCardPincode .creditCardPincode_inner input{
		width: 79%;
	    border: none;
	    float: none;
	    display: inline-block;
	    border-radius: 0;
	    vertical-align: top;
	}
	.App .creditCardPincode .creditCardPincode_inner label{
		background: transparent;
	}
	.creditCardPincode .creditCardPincode_inner button{
		float: none;
		display: inline-block;
		margin-left: -4px;
		width: 20%;
		border-radius: 0;
	}
	.creditCardPincode .creditCardPincode_inner{
		width: 100%;
	}
	.list_page_sec .list_page_sec_inner .all_rows .first_row{
		box-shadow: none;
		width: 48%;
		text-align: center;
		margin: 3px 3px !important;
		float: none;
		display: inline-block;
	}

	.eligibilityDiv{
		clear: both;
	    padding-top: 10px;
	    font-size: 16px;
	}
	footer{
		/*padding-bottom: 46px;*/
	}
	.sweet-alert {
    	z-index: 9999999999 !important;
	}

	.head.admin_header .headInner{
		height:60px ;
	}
	/* .overlay{
		background: url(../images/mb-overlay-img.png);
		background-size: 100%;
	} */
	footer{
		padding-bottom: 60px;
	}
	.forgetPin{
		width: 22%;
	}

	.loanForm_bottom_sec ul li img{
		width: auto;
		height: 15px;
	}
	.loanForm_bottom_sec p{
		line-height: 22px;
		font-size: 14px;
	}

	.sweet-alert{
		margin-top: -258px !important;
	}

	.sweet-alert h2{
		font-size: 26px;
	}


	/*--- registration page loader css --- */

	.div_spinner_relative{
		position: relative; text-align: center; margin: 0 auto; width: 100%;
	}
	#finacial-registration .registration-form .row #div-spinner{
		position: absolute; bottom: 0px;left: 0px;
	}
	.div_spinner_relative #div-spinner .spinner-border.text-dark{
		bottom: 0;
	}

	#finacial-registration #distBox .form-group label{
		margin-top: 0;
	}

	#finacial-registration .registration-form .row .div_spinner_relative.dt_financial_register #div-spinner{
		bottom: 40px !important;
	}

	footer p{
		font-size: 14px;
	}

	.footer_wrap{
		width: 95%;
		margin: 0 auto;
	}

	/*--- your earning ---*/

	.total_coins_page .total_coins_pageInner{
		width: 90%;
	}
	.total_coins_page .total_coins_pageInner .total_coins_left{
		width: 100%;
		margin: 0;
		display: block;
		border-radius: 35px;
		margin-bottom: 20px;
		padding: 15px 0;
	}
	.total_coins_page .total_coins_pageInner h3{
		padding: 25px 0;
		font-size: 24px;
	}
	.total_coins_page .total_coins_pageInner .toytal_coins_right{
		width: 100%;
		margin: 0;
		display: block;
		border-radius: 35px;
		margin-bottom: 20px;
		padding: 15px 0;
	}
	.total_coins_page .total_coins_pageInner .total_coins_left img{
		width: 150px;
	}
	.total_coins_page .total_coins_pageInner .total_coins_left span{
		font-size: 32px;
	}
	.total_coins_page .total_coins_pageInner .total_coins_left a{
		padding: 8px 0;
	}


	.total_coins_page .total_coins_pageInner .toytal_coins_right img{
		width: 150px;
	}
	.total_coins_page .total_coins_pageInner .toytal_coins_right span{
		font-size: 32px;
	}
	.total_coins_page .total_coins_pageInner .toytal_coins_right a{
		padding: 8px 0;
	}

	
	.complete_milestones_img{
		width: 100%;
	}
	.complete_milestones_num{
		width: 100%;
		padding-top: 0;
	}
	.complete_milestones_num ul li:nth-child(5) {
    margin-left: 10px;
	}
	.complete_milestones_num ul li:nth-child(4) {
	    margin-left: 0px;
	}
	.complete_milestones_num ul li:nth-child(3) {
	    margin-left: 10px;
	}
	.complete_milestones_num ul li:nth-child(2) {
	    margin-left: 10px;
	}
	.complete_milestones_num ul li {
	    display: inline-block;
	    background: linear-gradient(274deg, rgba(250,227,157,1) 0%, rgba(255,255,255,1) 100%);
	    border: 2px solid #efc259;
	    width: 30%;
	    border-radius: 10px;
	    height: 40px;
	    line-height: 25px;
	    font-size: 16px;
	    text-align: center;
	    font-weight: normal;
	    padding-top: 6px;
	    margin-bottom: 15px;
	}
	.complete_milestones h4{
		font-size: 18px;
	}
	.complete_milestones .milestones_para{
		       text-align: center;
    position: absolute;
    right: -9px;
    font-size: 14px;
    font-weight: 800;
    width: 73%;
    top: 150px;
    display: block;
    margin: 0 auto;
    width: 87px;
	}
	.total_coins_page{
		padding-bottom: 20px;
	}
	.total_coins p{
		font-size: 14px;
		display: none;
	}
	.total_coins .total_coins_inner{
		line-height: 12px;
		    height: 22px;
		    padding-left: 5px;
	}
	.total_coins img{
		width: 15px !important;
		vertical-align: middle;
	}
	.total_coins span{
		margin-top: 0;
		vertical-align: middle;
	}
	.total_coins a{
		line-height: normal !important;
	}

	/*--- survey page css ---*/

	.enquiry_form_page .enquiry_form_pageInner{
		width: 90%;
	}
	.headContent_row1 .col_1{
		width: 40%;
	}
	.headContent_row1 .col_1:nth-child(2){
		width: 56%;
	}
	.headContent_row1 .col_1:nth-child(3){
		margin-top: 10px;
	}
	.headContent_row1 .col_1:nth-child(4){
		width: 58%;
		margin-top: 15px;
	}
	.headContent_row1 .col_1 input.form-control{
		width: 100%;
	}
	#survey_step_one .enquiry_form_page_content .headRow .col_1{
		width: 100%;
	}
	.col_one_next{
		width: 100%;
	}
	.enquiry_form_bottom .col_one_next .save_input_anchor{
		margin-top: 0;
		border-radius: 50px;
	}
	.enquiry_form_OurPartners ul li{
		width: 82px;
	}
	.enquiry_form_OurPartners{
		padding-top: 10px;
	}
	.enquiry_form_page .enquiry_form_pageInner h3{
		font-size: 26px;
	}

	.enquiry_form_page_content .headRow .col_1:nth-child(3){
		display: none;
	}
	.enquiry_form_page_content .headRow .col_1:nth-child(4){
		display: none;
	}
	.enquiry_form_page_content .headRow .col_1{
		width: 49%;
	}
	.enquiry_form_page_content .headRow .col_1:nth-child(2){
		width: 49%;
	}

	#survey_step_two .headContent_row1 .col_1{
		width: 49%;
	}
	#survey_step_two .headContent_row1 .col_1:nth-child(2){
		width: 49%;
	}
	#survey_step_two .headContent_row1 .col_1:nth-child(3){
		width: 49%;
	}
	#survey_step_two .headContent_row1 .col_1:nth-child(4){
			width: 49%;
	}
	#survey_step_two .enquiry_form_bottom .col_one .name_input{
		padding-left: 15px;
		width: 100%;
		margin-bottom: 10px;
	}

	.enquiry_form_bottom .col_one{
		width:100%;
	}
	.enquiry_form_bottom .col_one_submit{
		width: 100%;
		margin-top: 10px;
	}
	.enquiry_form_bottom #prevId{
		width: 100%;
		margin-bottom: 20px;
	}
	.enquiry_form_bottom .col_one .save_input_anchor1{
		border-radius:50px;
	}

	.survey_banner img{
		width: 100%;
	}
	.survey_banner{
		padding-bottom: 0;
	}

	.complete_milestones_img ul li:nth-child(3) a span{
	display: none;
	}

	.complete_milestones_img ul li:nth-child(3) a span.r_ico{
		display: block;
	}
	.complete_milestones_img ul li:nth-child(5) a span{
		width: 50px;
	}

	.complete_milestones_img ul li:nth-child(5) a span.r_ico{
			width: 50px;
	}

	.complete_milestones_img ul li{
		padding-bottom: 10px;
	}




	/*--- education form design ---*/

	.container-fluid.applyform.eduacation_form_design{
		width: 100%;
		margin: 0;
		padding:0;
	}
	.education_heading{
		padding-top: 30px;
	}

	.thank_you_page{
		height: calc(100vh - 120px);
	}

	.thank_you_page_wrap{
		width: 90%;
		margin: 0 auto;
		text-align: center;
	}

	.thank_you_page_wrap h1{
		font-family: 'Urbanist', sans-serif;
		margin-top: 90px;
	}

	.go_back_btn_reports{
		width: 45%;
	}

	/*--- shop insurance design ---*/
	
	.insurance_right_img{
		display: none;
	}
	.shopInsurance_page_wrap{
		width: 90%;
	}
	.insurance_left_form{
		float: none;
	}
	.shopInsurance_form_content .first_row1 .basicDetail_column_1{
		margin-left: 0;
	}
	.shopInsurance_form_content .first_row1.building_value.content_value .shop_building_value{
		margin-left: 0;
	}
	.shopInsurance_form_content .first_row1 .basicDetail_column_1.companyNameField{
		width:100%;
		margin-bottom: 20px;
	}
	.shopInsurance_form_content .first_row1 .basicDetail_column_1.mobileNumberField{
		margin-left: 0;
		width: 100%;
		margin-bottom: 20px;
	}
	.shopInsurance_form_content .first_row1 .basicDetail_column_1.cityField{
		margin-left: 0;
		width: 100%;
	}
	.shopInsurance_form_content .first_row1.city_total_sumInsured .basicDetail_column_1.total_sum_insured .column_1.first_child{
		margin-left: 0;
	}
	.shopInsurance_form_content .first_row1.city_total_sumInsured .basicDetail_column_1.total_sum_insured .column_1{
			width: 49%;
			margin-left: 5px;
	}
	.shopInsurance_form_content .first_row1 .column_1 .radio_btn1 label{
		font-size: 15px;
	}
	.shopInsurance_form_content .first_row1 .column_1{
		width: 100%;
		margin-left: 0;
		margin-bottom: 20px;
	}
	.shopInsurance_form_content .first_row1.propertyType .column_1{
		width: 100%;
	}
	#shop_insurance_step_two .shopInsurance_form_content .get_a_plan{
		width: 100%;
	}
	.shopInsurance_form_content .first_row1 p{
		font-size: 18px;
	}
	.get_a_plan_list .planOne{
		width: 100%;
		margin-top: 15px;
	}
	.continue_btn a{
		width: 120px;
    margin: 20px 0;
    margin-top: 10px;
	}
	.success_page_wrap .success_page_inner{
		width: 90%;
	}
	.shopInsurance_form_content .first_row1.valueOfYourShop .column_1{
		width: 100%;
	}

	.main_sec .head .right_sec .referFriendBtn a.referFriend.R_two img{
		display: none;
	}
	.insurance_left_form h2{
		margin-top: 20px;
	}
	.shopInsurance_form_content .first_row1.businessTypeSelect{
		margin-top: 10px;
	}
	.insurance_left_form h2{
		font-size: 22px;
	}
	.get_a_plan_list{
		margin-bottom: 20px;
	}

	#frm_insurance .remove_nominee_row a{
		padding: 2px;
	    border: 3px solid #444;
	    border-radius: 50px;
	    right: -35px;
	    top: -34px;
	    width: 25px;
	    height: 25px;
	}
	.container-fluid.applyform.eduacation_form_design .row.form-group.submit_row_btn{
		padding: 20px 0;
	    width: 90%;
	    margin: 0 auto;
	    background: #fff;
	}

	/*--- training page css --*/

	.training_page_div .training_page_wrap{
		width: 100%;
	}
	.training_page_div .training_page_wrap .training_page_content .training_row1 .training_col1{
		width: 90%;
		float: none;
		margin: 0 auto !important;
		margin-bottom: 20px !important;
	}
	.training_page_div .training_page_wrap h2{
		font-size: 30px;
		padding-left: 30px;
		padding-right: 30px;
	}

	/*--- add/manage lead page css ---*/

	.add_manage_lead_content .add_manage_left{
		display: block;
		width: 90%;
		margin: 0 auto;
	}
	.add_manage_lead_content .add_manage_left .form-group{
		margin-bottom: 0;
	}

	.add_manage_lead_content .add_manage_left .form-group .col-md-6{
		margin-bottom: 15px;
	}
	.add_manage_lead_content .add_manage_left .form-group .col-md-4{
		margin-bottom: 15px;
	}

	.add_manage_lead_content .add_manage_right{
		display: none;
	}

	.add_manage_lead_sec{
		padding-bottom: 20px;
	}
	/*--- shop insurance payment ---*/

	.shop_insurance_payment .get_a_plan_list .planOne{
		width: 90%;
		margin: 0 auto !important;
		display: block;
		margin-top: 20px !important;
	}

	.shop_insurance_payment .get_a_plan_list .planOne ul{
		height: auto;
	}

	/*--- instant loan css ---*/

	.instant_loan1 .shopInsurance_form_content .first_row1 .basicDetail_column_1{
		margin-left: 0;
		margin-bottom: 20px;
		width: 100%;
	}

	.instant_loan1 .shopInsurance_form_content .first_row1{
		margin-top: 0 !important;

	}
	.instant_loan1 .continue_btn a{
		width: auto;
    	padding: 0 20px;
	}
	.instant_loan_cards .instant_loan_cards_wrap .col-lg-6{
		flex: 0 0 90%;
    	max-width: 90%;
    	margin: 0 auto !important;
    	margin-bottom: 20px !important;
	}
	.instant_loan_cards .instant_loan_cards_wrap .col-lg-6 .bank_logo{
		display: block;
		text-align: center;
		margin-bottom: 20px;
	}
	.instant_loan_cards .instant_loan_cards_wrap{
		width: 100%;
	}
	.instant_loan_cards .instant_loan_cards_wrap .col-lg-6 .bank_name_apply{
		text-align: center;
		display: block;
	}
	.instant_loan_cards .instant_loan_cards_wrap .col-lg-6 .bank_name_apply a{
		margin: 0 auto;
	}

	/*--- appointment page design ---*/


	/*--- appointment page design ---*/


	.appointment_tabs ul{
		/*display: none;*/
	}
	.appointment_form_page .appointment_form_wrap{
		width: 90%;
	}
	.appointment_form_page .appointment_form_wrap .top_head{
		width: 100%;
	}
	.appointment_form_page .appointment_form_wrap .top_head h3{
		
		padding-bottom: 0;
		font-size: 22px;
	}
	.appointment_form_page .appointment_form_wrap .top_head a{
		margin-top: 22px;
	    font-size: 14px;
	    padding: 7px 18px;
	}
	.appointment_tabs{
		margin-top: 20px;
	}
	.row.form-group.declare_ p{
		width: 91%;
	}
	.row.form-group.declare_1 p{
		width: 91%;
	}

	.appointment_tabs ul li{
		float: none;
	    width: 100%;
	    height: auto;
	    padding: 10px 0;
	    margin-bottom: 10px;
	    margin-left: 0;
	}
	.appointment_tabs ul li a img{
		margin-right: 30px;
	}
	.appointment_tabs ul li a img, .appointment_tabs ul li a span{
		display: inline-block;
		padding-top: 0;
	}
	.appointment_tabs ul li a{
		text-align: left;
		padding-left: 40px;
	}


	/*--- new header navigation css ---*/

	.head .header_navigation .hover_dropDown ul li.insurance_nav a, .head .header_navigation .hover_dropDown ul li.distributor_nav a{
		background-size: 23px;
    	background-position: 235px 16px;
	}

	
	
	.main_sec.report_page .hover_dropDown ul li a{
		color: #444;
	}
	.head .header_navigation .hover_dropDown ul li{
		font-size: 16px;
	}
	.head .header_navigation .hover_dropDown{
		background: #eee;
	    position: fixed;
	    z-index: 999;
	    width: 75%;
	    height: 100%;
	    display: none;
	    overflow-y: scroll;
	}
	.head .header_navigation .hover_dropDown ul ul.insurance_drop_down{
		position: inherit;
		margin-left: 20px;
		background: transparent;
	}
	.head .header_navigation .hover_dropDown ul li.more_nav:hover ul.more_dropDown{
		display: none;
		background: transparent;
	    margin-left: 0;
	    position: inherit;
	    box-shadow: none;
	}
	.head .header_navigation .hover_dropDown ul ul.more_dropDown{
		position: inherit;
		margin-left: 20px;
		background: transparent;
	}
	.head .header_navigation .hover_dropDown ul li.insurance_nav:hover a{
		background-size: 23px;
    	background-position: 235px 16px;
	}
	.head .header_navigation .hover_dropDown ul li.distributor_nav:hover a{
		background-size: 23px;
    	background-position: 235px 16px;
	}
	.head .header_navigation .hover_dropDown ul ul.distributor_dropDown{
		width: 85%;
		margin-left: 15px;
	}
	.main_sec.report_page .services_sec{
		margin-top: 0;
	}
	.head .header_navigation .hover_dropDown ul li.insurance_nav:hover ul.insurance_drop_down{
		display: none;
		position: inherit;
	    margin-left: 0;
	    background: rgba(0, 0, 0, .02);
	    box-shadow: none;
	    border-radius: 0;
	    width: 100%;
	}

	/*--- purpose base page css --*/

	.purpose_detail .purpose_detail_wrap{
		width: 90%;
		margin: 0 auto;
	}
	.purpose_detail .purpose_detail_wrap .first_sec .left_content{
		width: 100%;
		padding-top: 0;
		padding-bottom: 30px;
	}
	.purpose_detail .purpose_detail_wrap .first_sec .right_form{
		width: 100%;
	}
	.purpose_detail .purpose_detail_wrap .first_sec .right_form img{
		display: none;
	}
	.purpose_detail .purpose_detail_wrap .first_sec .right_form .card.card-success .row{
		margin: 0;
	}
	.purpose_detail .purpose_detail_wrap .first_sec .right_form .card.card-success .row .col-lg-4{
		margin-bottom: 15px;
	}
	.purpose_detail .purpose_detail_wrap .second_sec p.para-width{
		width: 100%;
	}
	.second_sec_wrap .right_img .slider-img{
		width: 96%;
		margin:0 auto;
	}

	.second_sec_wrap .right2{
		position: inherit;
		top: inherit;
		right: inherit;
		bottom: inherit;
		margin: 0;
		width: 100%;
		}
	.second_sec_wrap .left_img{
		float: none;
	}
	.second_sec_wrap .left_img{
		margin:0 auto;
	}
	.second_sec_wrap .right_img .boxTwo.payingTermBox.sixYear{
		width: 75px;
		padding: 2px 5px;
		margin-left: 5px;
	}
	.boxTwo.payingTermBox.sixYear::after{
		height: 22px;
		left: 41px !important;
		top: 28px;
	}

	.second_sec_wrap .right_img .boxes{
		margin-left: 0;
		vertical-align: top;
		margin-bottom: 0;
		width: 100%;
	}
	.second_sec_wrap .right_img .boxes .boxes{
		width: auto ;
	}
	.second_sec_wrap .right_img .boxes .boxTwo.payingTermBox.twelveYears2{
		margin-left: 140px !important; 
		position: relative;
	}
	.second_sec_wrap .right_img .boxes .boxTwo.payingTermBox.twelveYears2::after{
		margin-left: 0 !important; 
		left: 37px !important;
	}
	.second_sec_wrap .right_img .boxes .boxTwo.payingTermBox.sevenYears{
		margin-left: 67px !important;
		position: relative;
	}
	.second_sec_wrap .right_img .boxes .boxTwo.payingTermBox.sevenYears::after{
		margin-left: 0 !important;
	}
	.second_sec_wrap .right_img .boxes2 .boxOne.policyTermBox.elevenYears.twelveYears2{
		margin-left: 48px !important;
	}
	.second_sec_wrap .right_img .boxes2{
		vertical-align: top;
	}
	.second_sec_wrap .right_img .boxes2 .boxOne.policyTermBox.elevenYears{
		margin-left: 66px !important;
		position: relative;
		width: 85px;
		padding: 2px 5px;
	}
	.second_sec_wrap .right_img .boxes2 .boxOne.policyTermBox.elevenYears.twentyYears2{
		margin-left: 50px !important;
	}
	.second_sec_wrap .right_img .boxes2 .boxOne.policyTermBox.elevenYears::after{
		height: 22px;
		left: 45px;
		top: 28px;
		margin-left: 0 !important;
	}
	.second_sec_wrap .right_img{
		float: none;
	}
	.second_sec_wrap{
		padding: 20px 0;
	}
	.second_sec_wrap .right_img .boxes .boxTwo p{
		font-size: 11px;
		line-height: normal;
	}
	.purpose_detail .purpose_detail_wrap .second_sec{
		padding-top: 0;
	}
	.second_sec_wrap .right_img .boxes2 .boxOne p{
		font-size: 11px;
		line-height: normal;
	}
	.purpose_detail .purpose_detail_wrap .first_sec .left_content h2{
		font-size: 32px;
		line-height: 42px;
	}
	.purpose_detail .purpose_detail_wrap .first_sec .left_content p{
		font-size: 18px;
	}
	#debt-amount-slider{
		height: 55px;
	}
	.purpose_detail .purpose_detail_wrap .premium_content{
		margin-left: 0;
		width: 90%;
		margin: 0 auto;
		margin-top: 20px;
	}
	.second_sec_wrap .right_img .boxTwo.payingTermBox.eightYear{
		margin-left: 55px;
		position: relative;
		width: 85px;
		padding: 2px 5px;
	}
	.boxTwo.payingTermBox.eightYear::after{
		height: 22px;
		left: 36px;
		top: 28px;
	}
	.second_sec_wrap .right_img .boxes2 .boxOne.policyTermBox.thirteenYear{
		margin-left: 17px;
		position: relative;
		width: 85px;
		padding: 2px 5px;
	}
	.second_sec_wrap .right_img .boxes2 .boxOne.policyTermBox.thirteenYear::after{
		height: 22px;
		left: 45px;
		top: 28px;
	}
	.second_sec_wrap .right_img .boxes2 .boxOne.policyTermBox.twentyYear{
		margin-left: 12px;
		position: relative;
		width: 85px;
		padding: 2px 5px;
	}
	.second_sec_wrap .right_img .boxes2 .boxOne.policyTermBox.twentyYear::after{
			height: 22px;
		left: 45px;
		top: 28px;
	}
	.second_sec_wrap .right_img .boxTwo.payingTermBox.twelveYear{
		margin-left: 170px;
		position: relative;
		width: 85px;
		padding: 2px 5px;
	}
	.boxTwo.payingTermBox.twelveYear::after{
		height: 22px;
		left: 36px;
		top: 28px;
	}

	/* --- new purpose based listing page css --- */

	.purpose_based_service_inner{
		width: 95%;

	}
	.purpose_based_service_inner h2{
		font-size: 30px;
		line-height: 40px;
	}

	/*--- privacy policy ---*/

	.privacyPolicy_bharatearns_wrap{
		width: 90%;
		margin: 0 auto;
	}

	.head .header_navigation .hover_dropDown ul ul.distributor_drop_down{
		width: 90%;
	}
	.head .header_navigation .hover_dropDown ul ul.insurance_drop_down{
		width: 90%;
	}


	/*--- new purpose base page ---*/

	.purpose_detail .purpose_detail_wrap .first_sec .left_form{
		width: 100%;
		float:none;
	}
	.purpose_detail .purpose_detail_wrap .first_sec .right_img{
		float:none;
		width:100%;
		padding:25px 10px;
		margin-top: 0px;
		padding-bottom: 0;
	}
	.purpose_detail .purpose_detail_wrap .first_sec .right_img h2{
		font-size: 25px;
	}
	.purpose_detail .purpose_detail_wrap .first_sec .right_img .youGet h4{
		font-size: 18px;
		display: inline-block;
	}
	.purpose_detail .purpose_detail_wrap .first_sec .right_img .youGet{
		text-align: center;
		padding: 10px 0;
	}

	.purpose_detail .purpose_detail_wrap .first_sec .card-body .row.form-group .col-lg-4{
		padding-bottom: 0;
		margin-bottom: 12px;
	}

	.head.admin_header .header_navigation .hover_dropDown ul li a{
		padding: 15px 23px;
	}

	.changePin_sec .changePin_wrap.container{
		width: 90%;
		margin:0 auto;
		border-radius: 0;
		margin-top:20px;
	}
	.changePin_sec .changePin_wrap.container .left_changePin{
		width: 90%;
		margin: 0 auto;
	}
	.changePin_sec .changePin_wrap h3{
		margin-top: 0;
	}
	.changePin_sec .changePin_wrap .changePin_submit_cancel_btn .changePin_cancel{
		width:110px;
	}
	.changePin_sec .changePin_wrap .changePin_submit_cancel_btn .changePin_submit{
		width:110px;
	}
	.appointment_form_content .card-success .basic_detail_title h4{
		font-size: 15px;
		font-weight: 600;
	}
	.appointment_form_content .card-success .card-body{
		box-shadow: none;
	}
	.appointment_form_content .card-success .basic_detail_title{
		padding-bottom: 0;
	}
	.appointment_tabs .appointment_form_content form .row.form-group{
		margin-bottom: 0;
	    padding-bottom: 10px;
	    background: rgba(0, 0, 0, .02);
	    padding: 15px 5px;
	}
	.appointment_tabs .appointment_form_content form .row.form-group .col-lg-4{
		margin-bottom: 10px;
	}

	.appointment_tabs .appointment_form_content form .row.form-group .col-lg-3{
		margin-bottom: 10px;
	}
	.appointment_tabs .appointment_form_content form .row.form-group.declare_.annual_declare{
		margin: 10px 0;
	}
	.appointment_tabs .appointment_form_content form .row.form-group.declare_1.msme_declare{
		margin: 10px 0;
	}
	.previos_nxt a{
		width: auto;
		padding:0 15px;
	}
	.previos_nxt{
		margin-top:20px;
	}
	.card.card-success .card-body .row.form-group.head{
		background:transparent;
		padding-top: 5px;
	}
	.card.card-success .card-body .row.form-group.head .col-lg-5{
		display: none;
	}
	.appointment_tabs .appointment_form_content form .row.form-group .col-lg-3 h4{
		display: none;
	}
	.appointment_tabs .appointment_form_content form .row.form-group .col-lg-12 h3{
		font-size: 18px;
		text-align: center;
	}
	.appointment_tabs .appointment_form_content form .row.form-group .col-lg-3 span{
		display: block;
		text-align: center;
		font-size: 15px;
	}
	.appointment_tabs .appointment_form_content form .row.form-group .col-lg-3.bank_name_select span{
		text-align: left;
	}
	.card.card-success .card-body .row.form-group.head .col-lg-2{
		display: none;
	}
	.card.card-success .card-body .row.form-group.head .col-lg-1.status_div{
		display: none;
	}
	.card.card-success .card-body .row.form-group.head .col-lg-1.file_div{
		display: none;
	}
	.appointment_tabs .appointment_form_content form .row.form-group .col-lg-5 h4{
		margin-bottom: 10px;
    	font-size: 14px;
	}

	 .appointment_form_content .card-success .card-body .row.form-group .col-lg-5 h4{
    	line-height: normal;
	  }
	  .appointment_form_content .card-success .card-body .row.form-group .col-lg-3 input{
	    margin-top: 7px;
	  }
	  .appointment_form_content .card-success .card-body .row.form-group .col-lg-2 span{
	    line-height: normal;
	  }
	  .card.card-success .card-body .row.form-group .col-lg-5.address_proof h4{
	  	width:100%;
	  	display: block;
	  }
	  .card.card-success .card-body .row.form-group .col-lg-5.address_proof input{
	  	width:100%;
	  	display: block;
	  	margin-bottom: 10px;
	  }
	  .card.card-success .card-body .row.form-group .col-lg-5.address_proof select{
	  	width:100%;
	  	display: block;
	  	margin-bottom: 10px;
	  }
	  .appointment_tabs .appointment_form_content form .row.form-group .col-lg-3 label span{
	  		display: inherit;
	  }

	
	/*--- new social icons css ---*/

	.a2a_floating_style, .a2a_overlay_style{
		bottom: 0px !important;
		top: inherit !important;
		width: 100% !important;
		background: transparent !important;
		display: flex;
	}
	.a2a_vertical_style:not(.a2a_flex_style) a{
		display: flex !important;
		margin-left: -3px !important;
	}
	.a2a_svg {
	    width: 88px !important;
	    height: 60px !important;
	    padding: 4px 15px !important;
	    display: flex !important;
}


/*--- commercial packages ---*/

.pricing{
	max-width: 100%;
	margin-bottom: 20px;
}
.commercial_packages_inner .container{
	max-width: 100%;
}
.commercial_packages_inner .col1 {
    flex: 0 0 100%;
    max-width: 100%;
}
.pricing-error.pricing .price-box{
	padding-top:9px;
}
.pricing.pricing-warning .price-box, .pricing-success.pricing .price-box, .pricing-info.pricing .price-box{
	padding-top:20px;
}
.pricing:before{
	top:-15px;
}
#packages_select_mobile{
  display: block;
}
.commercial_packages_inner h2{
	font-size: 20px;
	padding-bottom: 10px;
}
 .plan {
    display: none;
}
#one {
    display: block;
}
.bottom_fix_mobile_div{
  display: block;
}

.bottom_fix_mobile_div .prev_nxt{
	display: none;
}
.commercial_packages_sec .commercial_packages_inner .container .row .col-md-6.col-lg-1.col1.plan .pricing.pricing-warning{
	margin-top:10px;
}
.pricing .options li{
	font-size: 16px;
	padding:12px 0px;
	padding-left: 30px;
}

.pricing.features-list .title{
	padding:10px 0;
}
.commercial_packages_inner{
	padding-bottom: 20px;
}
.pricing.pricing-warning .price-box, .pricing-success.pricing .price-box, .pricing-info.pricing .price-box{
		padding-top:9px;
}
.pricing-warning.pricing .options li{
	padding-left: 0;
}
.colors_borders{
	padding-bottom: 10px;
}
.commercial_packages_sec {
    padding-top: 25px;
}
.pricing .title a {
    font-size: 21px;
}
.pricing .price-box .price {
    font-size: 18px;
}
.pricing:before {
    width: 60%;
}
.pricing:before {
    top: -15px;
}

/* ---- OPD Insurance plans ------ */

.OPD_plan_sec .plan_one ul{
	overflow-y: inherit;
	height: auto;
}
.OPD_plan_sec .plan_one{
	margin-left: 0;
	width: 100%;
}

.OPD_plan_sec .plan_one ul li span{
	width:90%;
}

/*--- loan reports ---*/

.newLoanForm_content .loanFormSecOne .loanForm_verification .verification_left{
	width:90%;
	margin:0 auto;
	float:none;
}

.newLoanForm_sec .newLoanForm_wrap .newLoanForm_content .accordion .accordian_first_content .loanForm_verification .row.form-group{
	margin-bottom: 0;
}
.newLoanForm_sec .newLoanForm_wrap .newLoanForm_content .accordion .accordian_first_content .loanForm_verification .row.form-group .col-md-6{
	margin-bottom: 0;
	margin-bottom: 20px;
}
.newLoan_hr{
	margin-bottom: 0;
}
.newLoanForm_sec .newLoanForm_wrap .newLoanForm_content .accordion .accordian_first_heading{
	width: 90%;
	margin: 0 auto;
	margin-bottom: 10px;
}
.newLoanForm_content .loanFormSecOne .loanForm_verification .verification_right{
	display: none;
}

.activationKey_div{
	    width: 90%;
    margin: 0 auto;
    margin-top: 15px;
}
.activationKey_div p {
    text-align: center;
    margin: 5px 0;
    margin-top: 10px;
}

.newLoanForm_sec .newLoanForm_wrap .newLoanForm_content .accordian_first_content{
	width: 95%;
	margin:0 auto;
}

.newLoanForm_sec .newLoanForm_wrap .newLoanForm_content .accordian_first_content .row{
	margin-bottom: 0;
}

.newLoanForm_sec .newLoanForm_wrap .newLoanForm_content .accordian_first_content .row .col-md-3{
	margin-bottom: 20px;
}

.btn.btn-primary.open4{
	width:95px;
}

.newLoanForm_sec .newLoanForm_wrap .newLoanForm_content .loanForm_verification1{
	margin-top:20px;
}

.newLoanForm_sec .newLoanForm_wrap .newLoanForm_content .accordion .accordian_first_content .loanForm_verification1 .row.form-group{
	margin-bottom: 20px;
}

.newLoanForm_sec .newLoanForm_wrap .newLoanForm_content .accordion .accordian_first_content .loanForm_verification1 .row.form-group .col-md-3{
	margin-bottom: 10px;
}

.newLoanForm_sec .newLoanForm_wrap .newLoanForm_content .accordion .accordian_first_content .loanForm_verification1 .persoanlsal .col-md-3{
	margin-bottom: 20px;
}

/*--- 19 july issue loan ---*/

.newLoanForm_sec .newLoanForm_wrap .newLoanForm_content .accordion .accordian_first_heading h6{
	font-size: 20px;
	margin-right: 0;
}


}